import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

export const getBrackets = (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/get_brackets/5cb88367ce451995f7ed86f2`;
      const response = await axios.get(url, { headers });
      const { brackets } = calculateBrackets(user_id, response.data);
      resolve(brackets);
    } catch (error) {
      reject(error);
    }
  });
};

export const isMembers = (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/get_brackets/5cb88367ce451995f7ed86f2`;
      const response = await axios.get(url, { headers });
      const { isAdmin } = calculateBrackets(user_id, response.data);
      resolve(isAdmin);
    } catch (error) {
      reject(error);
    }
  });
};

function calculateBrackets(user_id, data_brackets) {
  let brackets = [];
  for (let index = 0; index < data_brackets.length; index++) {
    const bracket = data_brackets[index];
    for (
      let indexBrackets = 0;
      indexBrackets < bracket.groups.length;
      indexBrackets++
    ) {
      const { members } = bracket.groups[indexBrackets];
      const match = members.find((member) => member.user === user_id);
      if (match) {
        brackets.push(bracket);
        break;
      }
    }
  }
  if (brackets.length > 0) {
    return { isAdmin: true, brackets: brackets };
  } else {
    return { isAdmin: false, brackets: [] };
  }
}

export const getListDocs = (link) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/get_list_docs_private/${link}`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export const getBracketsDetail = (link) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/get_bracket/${link}`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}