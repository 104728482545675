import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import * as Page from "../Pages";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Page.Login} />
        <PrivateRoute
          path="/write/:id"
          component={Page.Write}
        />
        <PrivateRoute
          path="/priview/:id"
          component={Page.Priview}
        />
        <PrivateRoute
          path="/profile"
          component={Page.Profile}
        />
        <PrivateRoute
          path="/"
          component={Page.Home}
        />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.jwtTokenMapid ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
