import React, { useEffect, useState } from "react";
import { createEmpty, deleteBlog, getList } from "../App/Actions/blogActions";
import Navbar from "../Components/Navbar";
import IconPenFill from "../icons/IconPenFill";
import IconWrite from "../icons/IconWrite";
import IconTrash from "../icons/IconTrash";
import IconBxLoaderCircle from "../icons/IconBxLoaderCircle";
import IconEye from "../icons/IconEye";
import { Link, useHistory } from "react-router-dom";
import Modal from "../Components/Modal";
import IconClose from "../icons/IconClose";
import {
  getBrackets,
  getBracketsDetail,
  getListDocs,
} from "../App/Actions/bracket";
import { useAuth } from "../Context/AuthContext";
import IconChevronBackOutline from "../icons/IconChevronBackOutline";

export default function Home() {
  const history = useHistory();
  const { isAdmin } = useAuth();
  const [nav, setNav] = useState("publications");

  useEffect(() => {
    document.title = "Your Publications - Mapid Publications";

    return () => {
      document.title = "Mapid Publications";
    };
  }, []);

  const createNew = async () => {
    try {
      const result = await createEmpty();
      const { link } = result;
      history.push(`/write/${link}`);
    } catch (error) {
      console.error(error);
      alert("Failed create new publications!");
    }
  };

  return (
    <main>
      <Navbar>
        <button
          onClick={createNew}
          className="flex gap-1 items-center border-0 text-[#1265ae] hover:bg-blue-50 font-bold py-1 px-2 rounded"
        >
          <IconWrite className="w-5 h-5" />
          Write
        </button>
      </Navbar>
      <Greatings />
      {isAdmin && (
        <div className="grid grid-cols-2 mx-auto px-4 md:px-0 md:w-2/3 bg-white z-10 mt-4">
          <button
            onClick={() => setNav("publications")}
            className={`mb-2 pb-1 text-center font-semibold border-b-2 ${
              nav === "publications" ? "border-b-[#0ca5eb]" : ""
            }`}
          >
            Publications
          </button>
          <button
            onClick={() => setNav("admin")}
            className={`mb-2 pb-1 text-center font-semibold border-b-2 ${
              nav === "admin" ? "border-b-[#0ca5eb]" : ""
            }`}
          >
            Admin
          </button>
        </div>
      )}
      {isAdmin ? (
        nav === "publications" ? (
          <Publications />
        ) : (
          <Admin />
        )
      ) : (
        <Publications />
      )}
    </main>
  );
}

function Greatings() {
  const history = useHistory();

  const [greeting, setGreeting] = useState("");
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    let newGreeting = "";

    if (currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour < 18) {
      newGreeting = "Good Afternoon";
    } else {
      newGreeting = "Good Evening";
    }

    setGreeting(newGreeting);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 60) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const createNew = async () => {
    try {
      const result = await createEmpty();
      const { link } = result;
      history.push(`/write/${link}`);
    } catch (error) {
      console.error(error);
      alert("Failed create new publications!");
    }
  };

  return (
    <section className="mx-auto px-4 md:px-0 md:w-2/3 flex items-center justify-between border-b sticky top-0 bg-white z-10">
      <div className="">
        <h1 className="text-2xl md:text-4xl leading-none">{greeting}</h1>
        <p className="text-gray-500 font-bold mb-2">
          Tell The World Your Idea Now
        </p>
      </div>

      {showButton && (
        <button
          onClick={createNew}
          className="bg-[#1265ae] hover:bg-[#1b659d] text-white font-bold py-1 px-3 rounded flex items-center gap-2"
        >
          <IconPenFill />
          Write New
        </button>
      )}
    </section>
  );
}

function Publications() {
  const history = useHistory();

  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  const getListPublications = async () => {
    try {
      setLoading(true);
      const result = await getList();
      setPublications(result.reverse());
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListPublications();
  }, []);

  const deleteAction = async (id) => {
    try {
      const result = await deleteBlog(id);
      if (result.status === "Delete blog success") {
        getListPublications();
      }

      setShowModalDelete(false);
    } catch (error) {
      alert("Failed Delete Publications");
    }
  };

  const createNew = async () => {
    try {
      const result = await createEmpty();
      const { link } = result;
      history.push(`/write/${link}`);
    } catch (error) {
      console.error(error);
      alert("Failed create new publications!");
    }
  };

  return (
    <section className="mx-auto px-4 md:px-0 md:w-2/3">
      {loading ? (
        <IconBxLoaderCircle className="mx-auto animate-spin w-8 h-8 mt-4" />
      ) : (
        publications.map((publication, index) => (
          <div
            key={index}
            className="py-4 flex justify-between pb-8 relative border-b my-2"
          >
            <div className="w-2/3">
              <div className="flex gap-1 items-center">
                <p className="text-sm text-gray-600">
                  {formatDate(
                    publication.date_created
                      ? publication.date_created
                      : publication.date
                  )}
                </p>
                &bull;
                {publication.market_status.status !== "private" ? (
                  <span>
                    <span className="text-xs bg-green-700 px-2 text-white rounded-full capitalize">
                      {publication.market_status.status}
                    </span>
                    {publication.market_status.status === "paid" && (
                      <span>
                        <span className="mx-1">&bull;</span>
                        <span className="text-xs font-semibold">
                          Rp{" "}
                          {new Intl.NumberFormat().format(
                            publication.market_status.price ?? 0
                          )}
                        </span>
                      </span>
                    )}
                  </span>
                ) : (
                  <span className="text-xs bg-gray-600 px-2 text-white rounded-full">
                    Draft
                  </span>
                )}
              </div>
              <b>{publication.title}</b>
            </div>
            <div className="border w-36 h-20 relative rounded bg-gray-300">
              {publication.picture_url && (
                <a
                  href={publication.picture_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={publication.picture_url}
                    alt=""
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </a>
              )}
            </div>

            <div className="absolute bottom-2">
              <div className="flex gap-2">
                <Link
                  to={`/write/${publication.link}`}
                  className="rounded bg-[#0ca5eb] text-white px-2 font-bold text-sm flex items-center lg:block"
                >
                  edit
                </Link>
                <button
                  onClick={() => {
                    setShowModalDelete(true);
                    setIdDelete(publication._id);
                    setNameDelete(publication.title);
                  }}
                  className="rounded flex items-center text-red-800 hover:text-red-400"
                >
                  <IconTrash />
                </button>
                &bull;
                <div className="flex gap-1 items-center text-gray-600">
                  <IconEye />
                  <p className="text-sm">{publication.views_count}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {loading === false && publications.length === 0 && (
        <div className="text-center mt-12">
          <img
            alt="mapid publications"
            src="/write-mapid-publications.png"
            className="mx-auto w-60"
          />

          <h3>Write Awesome Publications Now</h3>
          <p>
            You don't have anyone publication, click button <b>Write</b> for
            write your first publication
          </p>
          <button
            onClick={createNew}
            className="bg-[#1265ae] hover:bg-[#1b659d] text-white font-bold py-1 px-3 rounded flex items-center gap-2 w-fit mx-auto mt-6"
          >
            <IconPenFill />
            Write
          </button>
        </div>
      )}

      {showModalDelete && (
        <Modal>
          <div className="bg-white shadow rounded p-4 lg:w-1/2 2xl:w-1/2 mx-auto">
            <div className="flex justify-between pb-2 mb-2 border-b-2">
              <p className="font-bold text-xl">Delete</p>
              <button
                className="ml-auto"
                onClick={() =>
                  setShowModalDelete((showModalDelete) => !showModalDelete)
                }
              >
                <IconClose className="w-6 h-6 text-gray-600" />
              </button>
            </div>
            <p>
              Are you sure to delete <b>{nameDelete}</b>?
            </p>
            <p className="text-sm">This process can't be undone</p>
            <button
              onClick={() => deleteAction(idDelete)}
              className="bg-red-500 text-white text-sm w-fit px-2 py-1 flex items-center justify-center rounded text-nowrap mx-auto mt-4"
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
    </section>
  );
}

function formatDate(unixTimestamp) {
  const date = new Date(unixTimestamp);

  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

function Admin() {
  const { user } = useAuth();
  const [brackets, setBrackets] = useState([]);
  const [listFolder, setListFolder] = useState([]);
  const [selectedBracket, setSelectedBracket] = useState("");
  useEffect(() => {
    admin();
    // eslint-disable-next-line
  }, []);

  const admin = async () => {
    try {
      const result = await getBrackets(user?._id);
      setBrackets(result);
    } catch (error) {
      console.log(error);
    }
  };

  const open = async (link) => {
    try {
      const { sub_brackets } = await getBracketsDetail(link);
      const doc = await getListDocs(link);

      for (let index = 0; index < doc.length; index++) {
        const element = doc[index];
        const match_index = sub_brackets.findIndex(
          (item) => item.link === element.sub_bracket
        );
        if (match_index !== -1) {
          if (sub_brackets[match_index].docs) {
            sub_brackets[match_index]?.docs.push(element);
          } else {
            sub_brackets[match_index] = {
              ...sub_brackets[match_index],
              docs: [element],
            };
          }
        }
      }

      console.log(sub_brackets);
      setListFolder(sub_brackets);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <main className="mx-auto px-4 md:px-0 md:w-2/3">
        <section className="grid grid-cols-2 md:grid-cols-3 gap-3 mt-4">
          {listFolder.length === 0 &&
            brackets.map((item, index) => (
              <div key={index} className="w-full border p-3 rounded-lg">
                <p className="font-semibold">{item.name}</p>
                <span
                  className="text-sm"
                  title={item.groups.map((group) => group.name).join(", ")}
                >
                  Groups:{" "}
                  {item.groups
                    .map((group) => group.name.slice(0, 10))
                    .join(", ")}
                </span>
                <button
                  onClick={() => {
                    open(item.link);
                    setSelectedBracket(item.name);
                  }}
                  className="rounded bg-[#0ca5eb] text-white px-3 py-1 mt-2 font-bold text-sm flex items-center lg:block"
                >
                  Open
                </button>
              </div>
            ))}
        </section>

        {listFolder.length !== 0 && (
          <div>
            <div className="flex gap-1 mb-4">
              <button
                onClick={() => setListFolder([])}
                className="font-semibold flex items-center"
              >
                <IconChevronBackOutline /> Back
              </button>{" "}
              / {selectedBracket}
            </div>
            {listFolder
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((folder, index) => (
                <div key={index} className="border p-2 rounded mb-8 shadow">
                  <p className="-mt-5 px-2 bg-white w-fit font-semibold mb-2 md:mb-4 text-lg rounded shadow">
                    {folder.name}
                  </p>
                  {folder.docs.map((doc, index) => (
                    <div
                      key={index}
                      className="pl-4 py-4 flex justify-between pb-8 relative border-b last:border-none my-2"
                    >
                      <div className="w-2/3">
                        <div className="flex gap-1 items-center">
                          <p className="text-sm text-gray-600">
                            {formatDate(doc.date)}
                          </p>
                          &bull;
                          {doc.isPublish ? (
                            <span>
                              <span className="text-xs bg-green-700 px-2 text-white rounded-full capitalize">
                                Publish
                              </span>
                            </span>
                          ) : (
                            <span className="text-xs bg-gray-600 px-2 text-white rounded-full">
                              Draft
                            </span>
                          )}
                        </div>

                        <b>{doc.title}</b>
                      </div>

                      <div className="border w-36 h-20 relative rounded bg-gray-300">
                        {doc.picture_url && (
                          <a
                            href={doc.picture_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={doc.picture_url}
                              alt=""
                              className="absolute inset-0 w-full h-full object-cover"
                            />
                          </a>
                        )}
                      </div>

                      <div className="absolute bottom-2">
                        <div className="flex gap-2 items-center">
                          <Link
                            to={`/write/${doc.link}`}
                            className="rounded bg-[#0ca5eb] text-white px-2 font-bold text-sm flex items-center lg:block"
                          >
                            edit
                          </Link>
                          <button
                            onClick={() => {
                              // setShowModalDelete(true);
                              // setIdDelete(doc._id);
                              // setNameDelete(doc.title);
                            }}
                            className="rounded flex items-center text-red-800 hover:text-red-400"
                          >
                            <IconTrash />
                          </button>
                          &bull;
                          <span className="text-sm">
                            {doc.authors[0].user.full_name ??
                              doc.authors[0].user.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        )}
      </main>
    </React.Fragment>
  );
}
