import React, { Component } from "react";
import { getDetail } from "../App/Actions/blogActions";
import {
  Editor,
  EditorState,
  CompositeDecorator,
  convertFromRaw,
} from "draft-js";
import IconMap from "../icons/IconMap";
import IconBxsMap from "../icons/IconBxsMap";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}
const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      style={{
        color: "#3b5998",
        textDecoration: "underline",
      }}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};
export default class Priview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      title: "",
      toc: null,
      createdAt: "",
      author: "",
      projects: [],
      layers: [],
      picture_url: "",
      picture_alt: "",
    };
    this.myRef = React.createRef();
    this.focus = () => this.myRef.current.focus();
  }
  async componentDidMount() {
    document.title = "Preview - Mapid Publications";

    try {
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
      ]);
      const id = window.location.href.split("/")[4];
      const publication = await getDetail(id);
      const contentState = JSON.parse(publication.editorState);
      const editorState = EditorState.createWithContent(
        convertFromRaw(contentState),
        decorator
      );

      let toc = [];
      const validToc = [
        "header-one",
        "header-two",
        "header-three",
        "header-four",
        "header-five",
        "header-six",
      ];
      for (let index = 0; index < contentState.blocks.length; index++) {
        const element = contentState.blocks[index];
        if (validToc.includes(element.type)) {
          toc.push(element);
        }
      }

      const createdAt = new Date(publication.date_created);
      const date = createdAt.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });

      const author = publication?.authors[0]?.user?.full_name
        ? publication?.authors[0]?.user?.full_name
        : publication?.authors[0]?.user?.username;

      const projects = publication.projects;
      const layers = publication.layers;
      const picture_alt = publication.picture_alt;
      const picture_url = publication.picture_url;

      this.setState({
        title: publication.title,
        toc,
        editorState,
        createdAt: date,
        author,
        projects,
        layers,
        picture_alt,
        picture_url,
      });
      document.title = `Preview - ${publication.title}`;
      delete publication.editorState;
      console.log(publication);
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const {
      title,
      toc,
      createdAt,
      author,
      projects,
      layers,
      picture_alt,
      picture_url,
    } = this.state;
    return (
      <main className="py-12 px-4 md:px-10 lg:px-28 2xl:px-44 flex">
        <div className="flex-1 hidden lg:block">
          <div className="sticky top-4 h-screen overflow-auto pb-6">
            <p className="text-xl font-bold">Table of Content</p>
            <hr className="my-2" />
            <ul className="font-bold text-sm">
              {toc &&
                toc.map((item, index) => {
                  let ml = "ml-0";
                  switch (item.type) {
                    case "header-two":
                      ml = "ml-2";
                      break;
                    case "header-three":
                      ml = "ml-4";
                      break;
                    case "header-four":
                      ml = "ml-6";
                      break;
                    case "header-five":
                      ml = "ml-8";
                      break;
                    case "header-six":
                      ml = "ml-10";
                      break;
                    default:
                      ml = "ml-0";
                      break;
                  }
                  return (
                    <li className={`mb-1 ${ml}`} key={index}>
                      {item.text}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="px-0 md:px-4 break-words lg:w-3/5 mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mt-0 mb-0">{title}</h1>
          <p className="text-base text-gray-600 my-4">
            {createdAt}
            <span className="mx-1">&bull;</span>
            <span className="">{author}</span>
          </p>
          <div className="flex flex-nowrap overflow-scroll w-full mt-2">
            {projects.map((project) => (
              <div
                key={project._id}
                className="p-4 rounded mr-2 mb-2 bg-blue-100 w-[12rem]"
              >
                <div className="flex items-center">
                  <IconMap />
                  <div className="ml-2">
                    <a
                      href={`https://geo.mapid.io/view/${project.geo_project._id}`}
                      target="_blank"
                      title="[GEODATA] CARBON STOCKS INDONESIA 2014"
                      className="underline"
                      rel="noreferrer"
                    >
                      Open Projects
                    </a>
                  </div>
                </div>
                <p className="text-sm">{project.geo_project.name}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-nowrap overflow-scroll w-full mt-2">
            {layers.map((layer) => (
              <div
                key={layer._id}
                className="p-4 rounded mr-2 mb-2 bg-green-100 w-[12rem]"
              >
                <div className="flex items-center">
                  <IconBxsMap />
                  <div className="ml-2">
                    <a
                      href={`https://geo.mapid.io/view/${layer.geo_layer.geo_project._id}?layer_id=${layer.geo_layer._id}`}
                      target="_blank"
                      title="[GEODATA] CARBON STOCKS INDONESIA 2014"
                      className="underline"
                      rel="noreferrer"
                    >
                      Open layers
                    </a>
                  </div>
                </div>
                <p className="text-sm">{layer.geo_layer.name}</p>
              </div>
            ))}
          </div>
          <figure>
            <img className="w-full" src={picture_url} alt={picture_alt} />
            <figcaption className="text-center">{picture_alt}</figcaption>
          </figure>
          <Editor
            editorState={this.state.editorState}
            readOnly={true}
            ref={this.myRef}
            spellCheck={false}
            blockRendererFn={mediaBlockRenderer}
          />
        </div>
        <div className="flex-1 hidden lg:block"></div>
      </main>
    );
  }
}

function mediaBlockRenderer(block) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
}

const Audio = (props) => {
  return <audio controls src={props.src} style={styles.media} />;
};

const Image = (props) => {
  return <img src={props.src} style={styles.media} alt={props.alt} />;
};

const Video = (props) => {
  return <video controls src={props.src} style={styles.media} />;
};

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, alt } = entity.getData();
  const type = entity.getType();

  let media;
  if (type === "audio") {
    media = <Audio src={src} />;
  } else if (type === "image") {
    media = <Image src={src} alt={alt} />;
  } else if (type === "video") {
    media = <Video src={src} />;
  }

  return media;
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 300,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  media: {
    width: "100%",
    whiteSpace: "initial",
  },
};