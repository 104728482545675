import axios from "axios";
import imageCompression from "browser-image-compression";
import { geoServerBaseUrl } from "./baseUrl";

/* GET METHOD */

export const getList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/get_by_user`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getListBlogCategory = () => {
  // https://geoserver.mapid.io/blog/cateogry
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/blog/cateogry`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getDetail = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlget = `${geoServerBaseUrl}/blog/get_detail_new/${id}`;
      const response = await axios.get(urlget, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/* POST METHOD */

export const uploadImage = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options_compression = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1500,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options_compression);

      const headers = {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      };
      const url = `${geoServerBaseUrl}/upload`;
      const formData = new FormData();
      formData.append("file", compressedFile);
      const response = await axios.post(url, formData, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const pushToGallery = (url, name) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const data = {
        url: url,
        name: name,
      };
      const urlpost = `${geoServerBaseUrl}/users/push_uploaded_picture`;
      const response = await axios.post(urlpost, data, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createEmpty = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const data = {
        domain: "geo.mapid.io",
      };
      const urlpost = `${geoServerBaseUrl}/blog/create_empty`;
      const response = await axios.post(urlpost, data, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/* POST METHOD */

export const save = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/edit_blog_general`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveEditor = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/edit_blog_editor`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const pushProject = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/push_project`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const pushLayer = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/push_layer`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeProject = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/remove_project`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeLayer = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/remove_layer`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setPrivate = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/set_private`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setPublic = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/set_public`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const proposeFree = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/propose_free`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const proposePaid = (body) => {
  // blog_id: "", price: 10000
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlpost = `${geoServerBaseUrl}/blog/propose_paid`;
      const response = await axios.post(urlpost, body, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/* DELETE METHOD */
export const deleteBlog = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const urlDelete = `${geoServerBaseUrl}/blog/delete_new/${id}`;
      const response = await axios.delete(urlDelete, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
