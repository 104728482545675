// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { getUser } from "../App/Actions/authActions";
import { isMembers } from "../App/Actions/bracket";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (localStorage.jwtTokenMapid) {
      getuser();
    }
  }, []);
  const getuser = async () => {
    try {
      const result = await getUser();
      const is_admin = await isMembers(result._id);
      setIsAdmin(is_admin);
      setUser(result);
    } catch (error) {
      alert("Get User Error! check your connections");
    }
  };

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    user,
    setUser,
    getuser,
    isAdmin,
    setIsAdmin,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
