// icon:software_font_size | Linea Iconset https://linea.io/ | Benjamin Sigidi
import * as React from "react";

function IconSoftware_font_size(props) {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path d="M28 43L43 1h1l15 42M33 29h21" />
      </g>
      <g
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path d="M5 43l8-23h1l8 23M7 36h13" />
      </g>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinejoin="bevel"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M56 63l7-7-7-7M8 49l-7 7 7 7"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M63 56H1"
      />
    </svg>
  );
}

export default IconSoftware_font_size;
