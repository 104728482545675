import React, { useEffect, useState } from "react";
import IconWrite from "../icons/IconWrite";
import IconEye from "../icons/IconEye";
import IconEyeInvisible from "../icons/IconEyeInvisible";
import IconBxLoaderCircle from "../icons/IconBxLoaderCircle";
import { login } from "../App/Actions/authActions";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { isMembers } from "../App/Actions/bracket";

export default function Login() {
  const history = useHistory();
  const { setUser, setIsAdmin } = useAuth();

  useEffect(() => {
    document.title = "Login - Mapid Publications";
    return () => {
      document.title = "Mapid Publications";
    };
  }, []);

  useEffect(() => {
    const getQueryParamFromURL = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");

      if (token) {
        localStorage.setItem("jwtTokenMapid", token);
        history.push("/");
      } else if (localStorage.jwtTokenMapid) {
        history.push("/");
      }
    };
    getQueryParamFromURL();
  }, [history]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setShowError(false);
      setShowLoading(true);
      const data = await login(username, password);
      setUser(data.user);

      const is_admin = await isMembers(data.user._id);
      setIsAdmin(is_admin);

      localStorage.setItem("jwtTokenMapid", data.token);
      history.push("/");
      setShowLoading(false);
    } catch (error) {
      setShowError(true);
      setShowLoading(false);
    }
  };

  return (
    <React.Fragment>
      <main>
        <section className="h-screen flex items-center justify-center">
          <div className="border shadow rounded p-4 w-96">
            <IconWrite className="w-10 h-10 mx-auto text-gray-500 mb-4 mt-4" />
            <p className="flex items-center gap-1 text-xl font-bold">
              Welcome to{" "}
              <span className="w-24">
                <img
                  src="https://mapid.co.id/img/mapid_logo_warna-min.png"
                  alt="mapid logo"
                />
              </span>{" "}
              Publications
            </p>

            <div className="flex flex-col mt-4">
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                className="border rounded p-1 border-blue-500"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="flex flex-col mt-1">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                className="border rounded p-1 border-blue-500"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="flex items-center mt-2 gap-1 text-sm"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <React.Fragment>
                    <IconEyeInvisible className="w-5 h-5 text-gray-600" />
                    Hide Password
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IconEye className="w-5 h-5 text-gray-600" />
                    Show Password
                  </React.Fragment>
                )}
              </button>
            </div>

            {showLoading ? (
              <button
                disabled
                className="bg-gray-400 text-white font-bold py-2 px-4 rounded w-full mt-4"
              >
                <IconBxLoaderCircle className="mx-auto animate-spin w-6 h-6" />
              </button>
            ) : (
              <button
                onClick={handleLogin}
                className="bg-[#1265ae] hover:bg-[#1b659d] text-white font-bold py-2 px-4 rounded w-full mt-4"
              >
                Login
              </button>
            )}

            {showError && (
              <div className="mt-4 p-2 border rounded border-red-600 bg-red-200 text-red-600">
                User name or password doesn't match
              </div>
            )}

            <p className="mt-12">
              This is a platform for writing MAPID publications, please log in
              using your MAPID account. If you don't have an account, click{" "}
              <a
                href="https://geo.mapid.io/register"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >
                here
              </a>
            </p>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}
