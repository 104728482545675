// icon:align-center | Feathericons https://feathericons.com/ | Cole Bemis
import * as React from "react";

function IconAi(props) {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   fill="none"
    //   viewBox="0 0 24 24"
    //   strokeWidth={1.5}
    //   stroke="currentColor"
    //   {...props}
    // >
    //   <path
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
    //   />
    // </svg>
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 4V6M6 11H4M28 11H26M6 16H4M28 16H26M6 21H4M28 21H26M11 26V28M16 4V6M16 26V28M21 4V6M21 26V28M9 26H23C23.7956 26 24.5587 25.6839 25.1213 25.1213C25.6839 24.5587 26 23.7956 26 23V9C26 8.20435 25.6839 7.44129 25.1213 6.87868C24.5587 6.31607 23.7956 6 23 6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9V23C6 23.7956 6.31607 24.5587 6.87868 25.1213C7.44129 25.6839 8.20435 26 9 26ZM10 10H22V22H10V10Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.179 20H11.2557L13.9261 12.7273H14.8352L17.5057 20H16.5824L14.4091 13.8778H14.3523L12.179 20ZM12.5199 17.1591H16.2415V17.9403H12.5199V17.1591ZM19.5192 12.7273V20H18.6385V12.7273H19.5192Z"
        fill="black"
      />
    </svg>
  );
}

export default IconAi;
