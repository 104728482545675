import axios from "axios";
import { aiServerUrl } from "./baseUrl";

export const postKeywordFromAi = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${aiServerUrl}/blog_ai`;
      const response = await axios.post(
        url,
        {
          description: body,
        },
        { headers }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postTitleFromAi = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${aiServerUrl}/blog_ai/title`;
      const response = await axios.post(
        url,
        {
          description: body,
        },
        { headers }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postDescriptionFromAi = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${aiServerUrl}/blog_ai/short_description`;
      const response = await axios.post(
        url,
        {
          description: body,
        },
        { headers }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}