import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import IconMenu from "../icons/IconMenu";
import IconClose from "../icons/IconClose";

export default function Navbar({ children }) {
  const { user } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <React.Fragment>
      <section className="p-4 grid grid-cols-2 md:flex items-center justify-between">
        <div className="w-32 md:w-40">
          <Link to="/">
            <img
              src="https://geo.mapid.io/static/media/logo_mapid.2bbafe7a165d58e2367eba9e3236415c.svg"
              alt="mapid logo"
            />
          </Link>
        </div>

        <div className="md:hidden ml-auto">
          {showMenu ? (
            <IconClose
              className="w-8 h-8 cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            />
          ) : (
            <IconMenu
              className="w-8 h-8 cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            />
          )}
        </div>

        <div
          className={`${
            showMenu ? "my-2" : "hidden"
          } md:flex gap-1 items-center col-span-2 md:mr-auto ml-auto md:ml-8`}
        >
          <a
            href="https://geo.mapid.io"
            target="_blank"
            rel="noreferrer"
            title="Back to GEO MAPID"
            className="flex gap-1 items-center font-semibold text-sm md:text-base"
          >
            {/* <img
              src={"https://mapid.co.id/img/icon-geo.png"}
              alt={"GEO MAPID"}
              className="w-10 h-10 rounded-full"
            /> */}
            Back to GEO MAPID
          </a>
        </div>
        <div
          className={`${
            showMenu ? "" : "hidden"
          } grid grid-cols-1 md:flex gap-3 md:gap-1 items-center col-span-2 ml-auto`}
        >
          <div className="ml-auto">{children}</div>
          {user && (
            <Link className="flex gap-1 items-center" to="/profile">
              <img
                src={user?.profile_picture?.url_compressed}
                alt={user.full_name ? user?.full_name.slice(0, 2) : user?.name.slice(0, 2)}
                className="w-10 h-10 border rounded-full shadow object-cover object-top flex items-center justify-center"
              />
              <span className="font-semibold whitespace-nowrap">
                {user?.full_name ? user?.full_name : user?.name}
              </span>
            </Link>
          )}
        </div>
      </section>
    </React.Fragment>
  );
}
