import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

export const searchLayers = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const url = `${geoServerBaseUrl}/layers_new/search_layers/${query}`;
      const response = await axios.get(url, { headers });
      resolve(response.data);
    } catch (error) {
      reject(error)
    }
  });
}