// icon:file-image-plus | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconFileImagePlus(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M13.09 20H6l6-6 1.88 1.88c.62-.98 1.48-1.78 2.52-2.28L18 12v1.09c.33-.05.66-.09 1-.09.34 0 .67.04 1 .09V8l-6-6H6c-1.11 0-2 .89-2 2v16a2 2 0 002 2h7.81c-.35-.61-.6-1.28-.72-2M13 3.5L18.5 9H13V3.5M8 9a2 2 0 110 4c-1.11 0-2-.89-2-2s.9-2 2-2m12 6v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2z" />
    </svg>
  );
}

export default IconFileImagePlus;
