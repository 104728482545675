import React from "react";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

export default function Profile() {
  const { user } = useAuth();

  const logout = async () => {
    try {
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <main>
      <Navbar>
        <Link to="/">Dashboard</Link>
      </Navbar>

      <section className="mx-auto px-4 md:px-0 md:w-2/3 flex items-center justify-between border-b sticky top-0 bg-white z-10">
        <p className="font-bold text-lg">Profile</p>
      </section>
      <section className="mx-auto px-4 md:px-0 md:w-2/3 mt-2">
        <img
          className="w-20 h-20 rounded-full object-cover object-top flex items-center justify-center border"
          src={user?.profile_picture?.url}
          alt={user?.full_name.slice(0, 2) ?? user?.name.slice(0, 2)}
        />
        <p className="mt-2">Name: {user?.full_name}</p>
        <p className="">Username: {user?.name}</p>
        <p className="">Email: {user?.email}</p>
        <p className="">Phone: {user?.phone_number}</p>
        <button
          onClick={logout}
          className="mt-4 py-1 px-4 rounded bg-red-500 text-white hover:bg-red-600"
        >
          Logout
        </button>
      </section>
    </main>
  );
}
