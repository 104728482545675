import React from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  CompositeDecorator,
  AtomicBlockUtils,
  convertFromRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import IconLink from "../icons/IconLink";
import IconBold from "../icons/IconBold";
import IconItalic from "../icons/IconItalic";
import IconUnderline from "../icons/IconUnderline";
import IconImage from "../icons/IconImage";
import IconEye from "../icons/IconEye";
import IconClose from "../icons/IconClose";
import Navbar from "../Components/Navbar";
import IconSave from "../icons/IconSave";
import {
  getDetail,
  getListBlogCategory,
  pushLayer,
  pushProject,
  pushToGallery,
  removeLayer,
  removeProject,
  save,
  saveEditor,
  uploadImage,
} from "../App/Actions/blogActions";
import Modal from "../Components/Modal";
import IconFileImagePlus from "../icons/IconFileImagePlus";
import IconBxLoaderCircle from "../icons/IconBxLoaderCircle";
import { useAuth } from "../Context/AuthContext";
import IconBlockquote from "../icons/IconBlockquote";
import IconOrderedList from "../icons/IconOrderedList";
import IconListUl from "../icons/IconListUl";
import IconBxCodeBlock from "../icons/IconBxCodeBlock";
import IconAlignCenter from "../icons/IconAlignCenter";
import IconSoftwareFontSize from "../icons/IconSoftware_font_size";
import IconSendFill from "../icons/IconSendFill";
import IconCheckCircle from "../icons/IconCheckCircle";
import { getProjects, searchProjects } from "../App/Actions/projectActions";
import { searchLayers } from "../App/Actions/layerActions";
import { useParams } from "react-router-dom";
import IconChevronBackOutline from "../icons/IconChevronBackOutline";
import { Link as LinkRRD } from "react-router-dom";
import Publish from "../Components/Publish";
import { useBlog } from "../Context/BlogContext";
import IconAi from "../icons/IconAi";
import { postTitleFromAi } from "../App/Actions/ai";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      style={{
        color: "#3b5998",
        textDecoration: "underline",
      }}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

class WriteDraftJs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      readOnly: false,
      showURLInput: false,
      url: "",
      urlType: "",
      categorys: [],
      showModalAddLink: false,
      urlLink: "",
      showModalAddImage: false,
      images: this.props?.auth?.user?.uploaded_pictures,
      imagesUrlSelected: "",
      imagesDescription: "",
      imagesUploadLoading: false,
      header: "",
      bold: false,
      italic: false,
      underline: false,
      showModalProjectLayer: false,
      navProjectLayer: "project",
      projects: [],
      layers: [],
      listProjects: [],
      listLayers: [],
      title: "",
      titleError: {
        status: false,
        message: "",
      },
      _id: "",
      saveLabel: "Save",
      showModalPublish: false,
      loadingAi: false,
      titleAi: [],
    };
    this.onChange = (editorState) => {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.handleSave();
      }, 1500);
      this.setState({ editorState });
    };
    this.handleKeyCommand = this.handleKeyCommand.bind(this);

    this.onURLChange = (e) => this.setState({ urlValue: e.target.value });
    this.myRef = React.createRef();
    this.focus = () => this.myRef.current.focus();

    this.addAudio = this._addAudio.bind(this);
    this.addImage = this._addImage.bind(this);
    this.addVideo = this._addVideo.bind(this);
    this.confirmMedia = this._confirmMedia.bind(this);
    this.onURLInputKeyDown = this._onURLInputKeyDown.bind(this);
    this.debounceTimer = null;
  }

  async componentDidMount() {
    document.title = "Write - Mapid Publications";

    try {
      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
      ]);

      const categorys = await getListBlogCategory();
      const projects = await getProjects();
      const publication = await getDetail(this.props.id);
      const contentState = convertFromRaw(JSON.parse(publication.editorState));
      const editorState = EditorState.createWithContent(
        contentState,
        decorator
      );
      this.setState({
        categorys,
        projects,
        editorState,
        title: publication.title,
        _id: publication._id,
        listProjects: publication.projects,
        listLayers: publication.layers,
      });
      document.title = publication.title;
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {
    document.title = "Mapid Publications";
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if props or state have changed
    if (this.props.auth !== prevProps.auth) {
      this.setState({
        images: this.props?.auth?.user?.uploaded_pictures,
      });
    }

    if (this.state.data !== prevState.data) {
      // Do something when 'data' state changes
    }
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }

  _onBoldClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
  }
  _onItalicClick() {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
    );
  }
  _onUnderlineClick() {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
    );
  }
  _onLinkClick() {
    this.setState({ showModalAddLink: false });
    const editorState = this.state.editorState;
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      {
        url: this.state.urlLink,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey
    );
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    });
    this.setState({
      editorState: newEditorState,
    });
  }
  _onPriview() {
    const path = window.location.pathname.split("/").pop();
    window.open(`/priview/${path}`, "_blank");
  }

  _confirmMedia(e) {
    e.preventDefault();

    const { editorState, urlType, imagesUrlSelected, imagesDescription } =
      this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      urlType,
      "IMMUTABLE",
      { src: imagesUrlSelected, alt: imagesDescription }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    this.setState(
      {
        // The third parameter here is a space string, not an empty string
        // If you set an empty string, you will get an error: Unknown DraftEntity key: null
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
        showURLInput: false,
        urlValue: "",
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
    this.setState({
      showModalAddImage: false,
      imagesUrlSelected: "",
      imagesDescription: "",
    });
  }

  _onURLInputKeyDown(e) {
    if (e.which === 13) {
      this._confirmMedia(e);
    }
  }

  _promptForMedia(type) {
    this.setState(
      {
        showURLInput: true,
        urlValue: "",
        urlType: type,
      },
      () => {
        setTimeout(() => this.refs.url.focus(), 0);
      }
    );
  }

  _addAudio() {
    this._promptForMedia("audio");
  }

  _addImage() {
    this._promptForMedia("image");
  }

  _addVideo() {
    this._promptForMedia("video");
  }

  toggleBlockType(params) {
    this.onChange(
      RichUtils.toggleBlockType(this.state.editorState, `${params}`)
    );
  }

  toggleInlineType = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  async addNewImage(params) {
    if (params.type.split("/")[0] !== "image") {
      return alert("Please select image");
    } else {
      try {
        this.setState({ imagesUploadLoading: true });
        const resultUploadImage = await uploadImage(params);
        const resultPushToGallery = await pushToGallery(
          resultUploadImage.data.Location,
          params.name
        );
        this.setState({
          images: [...this.state.images, resultPushToGallery],
          imagesUrlSelected: resultPushToGallery.url,
          urlType: "image",
          imagesUploadLoading: false,
        });
      } catch (error) {
        alert("Failed upload image, please try again!");
        this.setState({ imagesUploadLoading: false });
      }
    }
  }

  formatDateWithShortMonth(params) {
    const date = new Date(params);
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthIndex = date.getMonth();

    const shortMonthName = monthNamesShort[monthIndex];

    const dateString = `${shortMonthName} ${date.getDate()}, ${date.getFullYear()}`;

    return dateString;
  }

  findProjects(query) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      if (query !== "") {
        try {
          const projects = await searchProjects(query);
          this.setState({ projects });
        } catch (error) {
          console.error(error);
        }
      } else {
        const projects = await getProjects();
        this.setState({ projects });
      }
    }, 1000);
  }

  findLayers(query) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      if (query !== "") {
        try {
          const layers = await searchLayers(query);
          this.setState({ layers });
        } catch (error) {
          console.error(error);
        }
      } else {
        this.setState({ layers: [] });
      }
    }, 1000);
  }

  changeTitle(title) {
    this.setState({
      title: title,
      titleError: { status: false, message: "" },
    });
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      if (title !== "") {
        try {
          await save({
            blog_id: this.state._id,
            title,
          });
          this.setState({
            titleError: {
              status: false,
              message: "",
            },
            saveLabel: "Saved",
          });
          const special_characters = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/g;
          let slug = title.replace(special_characters, "");
          slug = slug.toLowerCase().split(" ").join("-");
          window.history.replaceState(null, "", `/write/${slug}`);
          document.title = title;
          setTimeout(() => {
            this.setState({ saveLabel: "Save" });
          }, 500);
        } catch (error) {
          this.setState({
            titleError: {
              status: true,
              message: "Already have the title, please try another title",
            },
            saveLabel: "Save",
          });
        }
      }
    }, 1000);
  }

  handleSave = async () => {
    try {
      this.setState({ saveLabel: "Save..." });
      const { editorState } = this.state;
      const raw = convertToRaw(editorState.getCurrentContent());
      const body = {
        blog_id: this.state._id,
        editorState: JSON.stringify(raw),
      };
      await saveEditor(body);
      this.setState({ saveLabel: "Saved" });
      setTimeout(() => {
        this.setState({ saveLabel: "Save" });
      }, 500);
    } catch (error) {
      console.error(error);
      alert("Failed save publication");
    }
  };

  pushAttachProject = async (params) => {
    try {
      await pushProject({
        blog_id: this.state._id,
        project: params,
      });
      const publication = await getDetail(this.props.id);
      this.setState({
        listProjects: publication.projects,
      });
    } catch (error) {
      alert("Failed Attach Project");
    }
  };

  pushAttachLayer = async (params) => {
    try {
      await pushLayer({
        blog_id: this.state._id,
        layer: params,
      });
      const publication = await getDetail(this.props.id);
      this.setState({
        listLayers: publication.layers,
      });
    } catch (error) {
      alert("Failed Attach Layer");
    }
  };

  pullAttachProject = async (params) => {
    try {
      await removeProject({
        blog_id: this.state._id,
        project: params,
      });
      const publication = await getDetail(this.props.id);
      this.setState({
        listProjects: publication.projects,
      });
    } catch (error) {
      alert("Failed Delete Attach Project");
    }
  };

  pullAttachLayer = async (params) => {
    try {
      await removeLayer({
        blog_id: this.state._id,
        layer: params,
      });
      const publication = await getDetail(this.props.id);
      this.setState({
        listLayers: publication.layers,
      });
    } catch (error) {
      alert("Failed Delete Attach Layer");
    }
  };

  handleTitleAi = async () => {
    this.setState({ loadingAi: true });
    try {
      const { editorState } = this.state;
      const raw = convertToRaw(editorState.getCurrentContent());
      const block = raw.blocks;
      const unstyled = block.filter((b) => b.type === "unstyled");
      const filterText = unstyled.filter((b) => b.text !== "");
      const texts = filterText.map((b) => b.text);
      if (texts.length >= 3) {
        const resultAi = await postTitleFromAi(texts);
        this.setState({
          title: resultAi[0],
          titleError: { status: false, message: "" },
          titleAi: resultAi,
        });
        this.changeTitle(resultAi[0]);
      }else{
        alert("Please write at least 3 paragraphs");
      }
      this.setState({ loadingAi: false });
    } catch (error) {
      this.setState({
        loadingAi: false,
        titleError: {
          status: true,
          message: "Failed for generate title from AI",
        },
      });
    }
  };

  render() {
    const {
      showModalAddLink,
      showModalAddImage,
      imagesUrlSelected,
      imagesDescription,
      imagesUploadLoading,
      showModalProjectLayer,
      navProjectLayer,
      projects,
      layers,
      title,
      titleError,
      saveLabel,
      listProjects,
      listLayers,
      showModalPublish,
      categorys,
      _id,
    } = this.state;

    const menuHeader = (
      <div className="flex items-center justify-between">
        <LinkRRD to="/">
          <p className="flex items-center">
            <IconChevronBackOutline />
            Back to home
          </p>
        </LinkRRD>
        <div className="text-right flex justify-end gap-2 text-xs lg:text-sm">
          <button
            onClick={() => this.handleSave()}
            className="flex gap-1 items-center border-0 text-white bg-[#0ca5eb] font-bold py-1 px-2 rounded"
          >
            <IconSave className="w-5 h-5" />
            {saveLabel}
          </button>
          <button
            onClick={() => this.setState({ showModalPublish: true })}
            className="flex gap-1 items-center border-0 text-white bg-[#69b225] font-bold py-1 px-2 rounded"
          >
            <IconSendFill className="w-4 h-4" />
            Publish
          </button>
          <button
            className="w-fit flex items-center text-gray-600 hover:bg-gray-200 font-bold py-1 lg:px-2 rounded"
            onClick={this._onPriview.bind(this)}
          >
            <IconEye className="h-5 w-5" /> Preview
          </button>
        </div>
      </div>
    );

    const titleNav = (
      <div>
        <div className="mt-2 flex">
          <textarea
            className={`w-full text-3xl bg-[#f9fbfd] border p-1 rounded ${
              titleError.status ? "border-red-600 border-2" : ""
            }`}
            rows={2}
            placeholder="Write title here"
            value={title}
            onChange={(e) => this.changeTitle(e.target.value)}
          ></textarea>

          <button className="border px-2" onClick={() => this.handleTitleAi()}>
            <IconAi
              className={`w-6 h-6 ${
                this.state.loadingAi ? "animate-spin" : ""
              }`}
            />
          </button>
        </div>
        <div>
          {titleError.status && (
            <p className="text-red-600 text-sm font-bold mb-2">
              *{titleError.message}
            </p>
          )}
        </div>
        <div className={`${this.state.titleAi.length === 0 ? "hidden" : ""}`}>
          <details>
            <summary>
              <span className="text-gray-600 font-bold">
                Other Title Generate by AI
              </span>
            </summary>
            <ol className="list-disc pl-8">
              {this.state.titleAi
                .slice(1, this.state.titleAi.length)
                .map((title, index) => (
                  <li
                    key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      this.setState({
                        title: title,
                        titleError: { status: false, message: "" },
                      });
                      this.changeTitle(title);
                    }}
                  >
                    {title}
                  </li>
                ))}
            </ol>
          </details>
        </div>
      </div>
    );

    const modalAddLink = showModalAddLink && (
      <Modal>
        <div className="bg-white shadow p-4 lg:w-1/2 2xl:w-1/3 mx-auto">
          <div className="flex justify-between pb-2 mb-2 border-b-2">
            <p className="font-bold text-xl">Add Link</p>
            <button
              className="ml-auto"
              onClick={() => this.setState({ showModalAddLink: false })}
            >
              <IconClose className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <div className="flex flex-col">
            <label htmlFor="url" className="font-semibold">
              Link URL
            </label>
            <input
              id="url"
              type="url"
              className="border px-2 py-1 rounded"
              onChange={(e) => this.setState({ urlLink: e.target.value })}
            />
          </div>
          <button
            onClick={this._onLinkClick.bind(this)}
            className="bg-[#1265ae] hover:bg-[#1b659d] text-white font-bold py-2 px-4 rounded w-fit mt-4 mx-auto"
          >
            Submit
          </button>
        </div>
      </Modal>
    );

    const modalAddImage = showModalAddImage && (
      <Modal>
        <div className="bg-white shadow rounded p-4 lg:w-2/3 2xl:w-1/2 mx-auto">
          <div className="flex justify-between pb-2 mb-2 border-b-2">
            <p className="font-bold text-xl">Add Image</p>
            <button
              className="ml-auto"
              onClick={() =>
                this.setState({
                  showModalAddImage: false,
                  imagesUrlSelected: "",
                  imagesDescription: "",
                })
              }
            >
              <IconClose className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <p className="my-2 font-bold text-xl">Add New Images</p>
          {imagesUploadLoading ? (
            <button className="w-36 h-24 flex items-center justify-center bg-gray-100">
              Loading <IconBxLoaderCircle className="animate-spin w-6 h-6" />
            </button>
          ) : (
            <button
              className="w-36 h-24 flex items-center justify-center bg-gray-100"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <IconFileImagePlus className="w-12 h-12 text-gray-600" />
            </button>
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            className="hidden"
            onChange={(e) => this.addNewImage(e.target.files[0])}
          />
          <hr className="mt-4" />
          <p className="my-2 font-bold text-xl">Your Images Gallery</p>
          <div className="flex flex-wrap gap-3 h-[30dvh] overflow-y-auto">
            {this.state.images
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((data, index) => (
                <button
                  key={index}
                  onClick={() =>
                    this.setState({
                      imagesUrlSelected: data.url,
                      urlType: "image",
                    })
                  }
                  className={`w-36 h-24 flex items-center justify-center bg-gray-100 cursor-pointer hover:border-4 hover:border-gray-400 ${
                    imagesUrlSelected === data.url
                      ? "border-4 border-blue-600"
                      : ""
                  }`}
                >
                  <img
                    src={data.url}
                    alt={data.name}
                    className="object-cover w-36 h-20"
                  />
                </button>
              ))}
          </div>
          <hr className="mt-4" />
          <p className="my-2 font-bold text-xl">Add Your Image Descriptions*</p>
          <p className="text-sm">
            Add description alternative concise and informative, describe the
            essence of the image.
          </p>
          <p className="text-sm">
            <b>Example</b>, Image of a sleeping cat:{" "}
            <b>"A black cat lies on the sofa with its eyes closed."</b>
          </p>
          <div>
            <input
              type="text"
              placeholder="Type here..."
              className="border w-full py-1 px-2"
              onChange={(e) =>
                this.setState({ imagesDescription: e.target.value })
              }
            />
          </div>
          {imagesDescription === "" || imagesUrlSelected === "" ? (
            <div className="mt-4">
              <p className="mb-1">*Image & Descriptions is required!</p>
              <button className="bg-gray-400 cursor-not-allowed text-white font-bold py-2 px-4 rounded w-fit mx-auto">
                Submit
              </button>
            </div>
          ) : (
            <button
              onClick={this.confirmMedia}
              className="bg-[#1265ae] hover:bg-[#1b659d] text-white font-bold py-2 px-4 rounded w-fit mt-4 mx-auto"
            >
              Submit
            </button>
          )}
        </div>
      </Modal>
    );

    const modalProjectLayer = showModalProjectLayer && (
      <Modal>
        <div className="bg-white shadow rounded p-4 lg:w-2/3 2xl:w-1/2 mx-auto">
          <div className="flex justify-between items-center pb-2 mb-2 border-b-2">
            <p className="font-bold text-xl">Import</p>
            <button
              className="ml-auto"
              onClick={() =>
                this.setState({
                  showModalProjectLayer: false,
                })
              }
            >
              <IconClose className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => this.setState({ navProjectLayer: "project" })}
              className={`text-center p-1 border-b-2 w-full ${
                navProjectLayer === "project"
                  ? "border-blue-600"
                  : "border-white"
              }`}
            >
              Project
            </button>
            <button
              onClick={() => this.setState({ navProjectLayer: "layer" })}
              className={`text-center p-1 border-b-2 w-full ${
                navProjectLayer === "layer" ? "border-blue-600" : "border-white"
              }`}
            >
              Layer
            </button>
          </div>
          <div className="mt-4">
            {navProjectLayer === "project" ? (
              <React.Fragment>
                <p>Search Project</p>
                <small>Search project of yours then attach it here.</small>
                <input
                  type="text"
                  className="border border-blue-400 rounded p-2 w-full mt-2"
                  onChange={async (e) => this.findProjects(e.target.value)}
                />
                <div className="overflow-y-auto h-40 lg:h-[60dvh] gap-2 flex flex-col mt-4 hover:pr-2">
                  {projects.map((project, index) => (
                    <div
                      key={index}
                      className="p-2 border flex justify-between items-center"
                    >
                      <div>
                        <p>{project.name}</p>
                        <small>
                          {this.formatDateWithShortMonth(project.date_created)}
                        </small>
                      </div>
                      {listProjects.find(
                        (obj) => obj.geo_project._id === project._id
                      ) ? (
                        <p className="text-sm text-green-600 font-bold rounded flex items-center">
                          <IconCheckCircle /> Attached
                        </p>
                      ) : (
                        <button
                          onClick={() => this.pushAttachProject(project)}
                          className="text-xs bg-[#0ca5eb] text-white font-bold py-2 px-4 rounded"
                        >
                          Attach
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>Search Layer</p>
                <small>Search layer of yours then attach it here.</small>
                <input
                  type="text"
                  className="border border-blue-400 rounded p-2 w-full mt-2"
                  onChange={(e) => this.findLayers(e.target.value)}
                />
                <div className="overflow-y-auto h-40 lg:h-[60dvh] gap-2 flex flex-col mt-4 hover:pr-2">
                  {layers.map((layer, index) => (
                    <div
                      key={index}
                      className="p-2 border flex justify-between items-center"
                    >
                      <div>
                        <p className="font-semibold">{layer.name}</p>
                        <small>
                          {this.formatDateWithShortMonth(layer.date_created)}{" "}
                          &bull; <b>Project:</b> {layer.geo_project.name}
                        </small>
                      </div>
                      {listLayers.find(
                        (obj) => obj.geo_layer._id === layer._id
                      ) ? (
                        <p className="text-sm text-green-600 font-bold rounded flex items-center">
                          <IconCheckCircle /> Attached
                        </p>
                      ) : (
                        <button
                          onClick={() => this.pushAttachLayer(layer)}
                          className="text-xs bg-[#0ca5eb] text-white font-bold py-2 px-4 rounded"
                        >
                          Attach
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    );

    const modalPublish = showModalPublish && (
      <Modal>
        <div className="bg-white shadow rounded p-4 lg:w-2/3 2xl:w-1/2 mx-auto">
          <div className="flex justify-between pb-2 mb-2 border-b-2">
            <p className="font-bold text-xl">Publish</p>
            <button
              className="ml-auto"
              onClick={() =>
                this.setState({
                  showModalPublish: false,
                })
              }
            >
              <IconClose className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <p>
            Before publishing, you must include a thumbnail image, keywords,
            short description etc
          </p>

          <Publish
            publication={{
              title: title,
              categorys: categorys,
              _id: _id,
              link: this.props.id,
            }}
          />
        </div>
      </Modal>
    );

    const menuBar = (
      <div className="flex flex-wrap items-center justify-start gap-1">
        <BlockStyleControls
          editorState={this.state.editorState}
          onClick={(params) => this.toggleBlockType(params)}
        />
        |
        <InlineStyleControls
          editorState={this.state.editorState}
          onClick={(params) => this.toggleInlineType(params)}
        />
        |
        <button
          className="bg-gray-200 px-2 h-8 w-8 flex items-center justify-center rounded"
          onClick={() => this.setState({ showModalAddLink: true })}
        >
          <IconLink />
        </button>
        <button
          className="bg-gray-200 px-2 h-8 w-8 flex items-center justify-center rounded"
          onClick={() => this.setState({ showModalAddImage: true })}
        >
          <IconImage />
        </button>
        |
        <button
          className="bg-gray-200 px-2 h-8 flex items-center justify-center rounded"
          onClick={() => this.setState({ showModalProjectLayer: true })}
        >
          Import Projects/Layer
        </button>
      </div>
    );

    const menuAttach = (
      <div className="mt-2">
        <details>
          <summary>
            <span className="cursor-pointer">Project or Layer Attached</span>
          </summary>
          <div>
            <p>Project:</p>
            <div className="w-full flex flex-nowrap gap-2 pb-1 lg:pb-2 overflow-x-auto">
              {listProjects.map((e, i) => (
                <div
                  key={i}
                  className="border p-1 rounded bg-[#0ca5eb] text-white"
                >
                  <div className="w-32 text-xs">
                    <p className="font-bold">{e.geo_project.name}</p>
                    <div className="flex justify-between">
                      <span>
                        {e.geo_project.isPublic ? "Public" : "Not Public"}
                      </span>
                      <button
                        onClick={() => this.pullAttachProject(e.geo_project)}
                        className="bg-red-500 text-white rounded ml-auto"
                      >
                        <IconClose />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <p>Layer:</p>
            <div className="w-full flex flex-nowrap gap-2 pb-1 lg:pb-2 overflow-x-auto">
              {listLayers.map((e, i) => (
                <div
                  key={i}
                  className="border p-1 rounded bg-[#69b225] text-white"
                >
                  <div className="w-32 text-xs">
                    <p className="font-bold">{e.geo_layer.name}</p>
                    <div className="flex justify-between">
                      <span>
                        {e.geo_layer.isPublic ? "Public" : "Not Public"}
                      </span>
                      <button
                        onClick={() => this.pullAttachLayer(e.geo_layer)}
                        className="bg-red-500 text-white rounded ml-auto"
                      >
                        <IconClose />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </details>
      </div>
    );

    return (
      <main className="bg-[#f9fbfd]">
        <Navbar />
        <div className="px-4 md:px-12 lg:w-2/3 2xl:w-1/2 mx-auto">
          {menuHeader}
          {titleNav}
          <div className="sticky top-0 bg-[#f9fbfd] border-b-2 pt-2 lg:pt-4 pb-4 z-20">
            {menuBar}
            {menuAttach}
          </div>
          <div
            className="border min-h-[1500px] bg-white p-4 mb-4"
            onClick={this.focus}
          >
            <Editor
              blockRendererFn={mediaBlockRenderer}
              blockStyleFn={getBlockStyle}
              editorState={this.state.editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              readOnly={this.state.readOnly}
              ref={this.myRef}
              spellCheck={false}
              customStyleMap={styleMap}
            />
          </div>
        </div>

        {modalAddLink}
        {modalAddImage}
        {modalProjectLayer}
        {modalPublish}
      </main>
    );
  }
}

function BlockStyleControls(props) {
  const BLOCK_TYPES = [
    { label: "H1", style: "header-one", icons: "H1" },
    { label: "H2", style: "header-two", icons: "H2" },
    { label: "H3", style: "header-three", icons: "H3" },
    { label: "H4", style: "header-four", icons: "H4" },
    { label: "H5", style: "header-five", icons: "H5" },
    { label: "H6", style: "header-six", icons: "H6" },
    { label: "Blockquote", style: "blockquote", icons: <IconBlockquote /> },
    { label: "UL", style: "unordered-list-item", icons: <IconListUl /> },
    { label: "OL", style: "ordered-list-item", icons: <IconOrderedList /> },
    { label: "Code Block", style: "code-block", icons: <IconBxCodeBlock /> },
    { label: "Center", style: "center", icons: <IconAlignCenter /> },
  ];

  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <React.Fragment>
      {BLOCK_TYPES.map((type) => (
        <button
          key={type.style}
          className={`${
            type.style === blockType ? "bg-[#0ca5eb] text-white" : "bg-gray-200"
          } h-8 w-8 flex items-center justify-center rounded`}
          onClick={() => props.onClick(type.style)}
        >
          {type.icons}
        </button>
      ))}
    </React.Fragment>
  );
}

function InlineStyleControls(props) {
  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD", icons: <IconBold /> },
    { label: "Italic", style: "ITALIC", icons: <IconItalic /> },
    { label: "Underline", style: "UNDERLINE", icons: <IconUnderline /> },
    { label: "Monospace", style: "CODE", icons: <IconSoftwareFontSize /> },
  ];

  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <React.Fragment>
      {INLINE_STYLES.map((type) => (
        <button
          key={type.style}
          className={`${
            currentStyle.has(type.style)
              ? "bg-[#0ca5eb] text-white"
              : "bg-gray-200"
          } h-8 w-8 flex items-center justify-center rounded`}
          onClick={() => props.onClick(type.style)}
        >
          {type.icons}
        </button>
      ))}
    </React.Fragment>
  );
}

function mediaBlockRenderer(block) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
}

const Audio = (props) => {
  return <audio controls src={props.src} style={styles.media} />;
};

const Image = (props) => {
  return <img src={props.src} style={styles.media} alt={props.alt} />;
};

const Video = (props) => {
  return <video controls src={props.src} style={styles.media} />;
};

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, alt } = entity.getData();
  const type = entity.getType();

  let media;
  if (type === "audio") {
    media = <Audio src={src} />;
  } else if (type === "image") {
    media = <Image src={src} alt={alt} />;
  } else if (type === "video") {
    media = <Video src={src} />;
  }

  return media;
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 300,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  media: {
    width: "100%",
    whiteSpace: "initial",
  },
};

const styleMap = {
  CODE: {
    backgroundColor: "red",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "code-block":
      return "RichEditor-code-block";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}

export default function Write() {
  const auth = useAuth();
  const { id } = useParams();
  const { general, setGeneral } = useBlog();

  React.useEffect(() => {
    initialPublication();
    // eslint-disable-next-line
  }, []);

  const initialPublication = async () => {
    try {
      const publication_detail = await getDetail(id);
      let statusActive = 0;
      switch (publication_detail?.market_status?.status) {
        case "public":
          statusActive = 1;
          break;
        case "free":
          statusActive = 2;
          break;
        case "paid":
          statusActive = 3;
          break;
        default:
          statusActive = 0;
          break;
      }
      setGeneral({
        ...general,
        thumbnail: publication_detail?.picture_url ?? "",
        thumbnail_description: publication_detail?.picture_alt ?? "",
        description: publication_detail?.short_description ?? "",
        topics: publication_detail?.topics ?? [],
        category: publication_detail?.category_id[0] ?? "",
        faq: publication_detail?.faq ?? {
          mainEntity: [],
        },
        price: publication_detail?.market_status?.price ?? 0,
        statusActive: statusActive,
        address: publication_detail?.address ?? null,
        location: publication_detail?.location ?? null,
      });
    } catch (error) {
      // alert("Get Publication Error! check your connections");
      console.log(error);
    }
  };

  return <WriteDraftJs auth={auth} id={id} />;
}
