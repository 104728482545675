import React, { useEffect } from "react";

export default function Modal({ children }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 p-2 bg-black bg-opacity-50 h-[100dvh] overflow-auto z-20">
      {children}
    </div>
  );
}
