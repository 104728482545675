import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

export const login = (username, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const postLogin = await axios.post(`${geoServerBaseUrl}/users/login`, {
        name: username,
        password: password,
      });
      resolve(postLogin.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { accesstoken: localStorage.jwtTokenMapid };
      const user = await axios.get(`${geoServerBaseUrl}/users`, { headers });
      resolve(user.data);
    } catch (error) {
      reject(error);
    }
  });
};
