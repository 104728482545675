import React, { useState, useEffect } from "react";
import IconCaretRight from "../icons/IconCaretRight";
import IconFileImagePlus from "../icons/IconFileImagePlus";
import { useAuth } from "../Context/AuthContext";
import IconClose from "../icons/IconClose";
import IconLocationPin from "../icons/IconLocationPin";
import { useBlog } from "../Context/BlogContext";
import {
  pushToGallery,
  uploadImage,
  save,
  setPublic,
  proposeFree,
  proposePaid,
  setPrivate,
  getDetail,
} from "../App/Actions/blogActions";
import { useHistory } from "react-router-dom";
import MapGL, { NavigationControl } from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { editLocation, generateCity } from "../App/Actions/generalActions";
import IconBxLoaderCircle from "../icons/IconBxLoaderCircle";
import { postDescriptionFromAi, postKeywordFromAi } from "../App/Actions/ai";
import IconAi from "../icons/IconAi";

export default function Publish({ publication }) {
  const history = useHistory();
  const { general } = useBlog();
  const [nav, setNav] = useState([
    {
      name: "Thumbnail",
      active: true,
      component: <Thumbnail />,
    },
    {
      name: "Short Description",
      component: <ShortDescription publication={publication} />,
    },
    {
      name: "Keyword",
      component: <Keyword publication={publication} />,
    },
    {
      name: "Category",
      component: <Category categorys={publication.categorys} />,
    },
    {
      name: "FAQ",
      component: <FAQ />,
    },
    {
      name: "Location",
      component: <EditLocation publication={publication} />,
    },
    {
      name: "Status",
      component: <Status />,
    },
    {
      name: "Publish",
      component: <Summary publication={publication} />,
    },
  ]);
  const [navCount, setNavCount] = useState(0);
  const onPrev = () => {
    if (navCount > 0) {
      setNavCount(navCount - 1);
      setNav(
        nav.map((nav, i) =>
          navCount - 1 === i
            ? { ...nav, active: true }
            : { ...nav, active: false }
        )
      );
    }
  };
  const onNext = () => {
    setNavCount(navCount + 1);
    setNav(
      nav.map((nav, i) =>
        navCount + 1 === i
          ? { ...nav, active: true }
          : { ...nav, active: false }
      )
    );
  };
  const onSave = async () => {
    try {
      const body = {
        blog_id: publication?._id ?? "",
        title: publication?.title ?? "Untitled Document",
        title_en: publication?.title ?? "Untitled Document",
        picture_alt: general?.thumbnail_description ?? "",
        picture_url: general?.thumbnail ?? "",
        topics: general?.topics ?? [],
        category_id: general?.category ? [general?.category] : [],
        faq: general?.faq ?? {},
        short_description: general?.description ?? "",
      };
      const result = await save(body);
      if (result.status === "Edit general success") {
        switch (general?.statusActive) {
          case 1:
            await setPublic({ blog_id: publication?._id ?? "" });
            break;
          case 2:
            await proposeFree({ blog_id: publication?._id ?? "" });
            break;
          case 3:
            await proposePaid({
              blog_id: publication?._id ?? "",
              price: general?.price ?? 0,
            });
            break;
          default:
            await setPrivate({ blog_id: publication?._id ?? "" });
            break;
        }

        history.push("/");
      }
    } catch (error) {
      alert("Failed save data, please try again!");
    }
  };
  return (
    <React.Fragment>
      <div className="flex flex-nowrap overflow-auto mt-2 pb-2">
        {nav.map((item, index) => {
          return (
            <div key={index} className="flex items-center">
              <button
                onClick={() => {
                  setNav(
                    nav.map((nav, i) =>
                      index === i
                        ? { ...nav, active: true }
                        : { ...nav, active: false }
                    )
                  );
                  setNavCount(index);
                }}
                className={`${
                  item.active ? "bg-[#0ca5eb] text-white" : "bg-gray-200"
                } h-8 w-fit px-4 flex items-center justify-center rounded text-nowrap`}
              >
                {item.name}
              </button>
              {index !== nav.length - 1 && <IconCaretRight />}
            </div>
          );
        })}
      </div>
      <hr className="mt-4" />
      <main className="mt-2">
        {nav.map((item, index) => {
          if (!item.active) return null;
          return <div key={index}>{item.component}</div>;
        })}
      </main>
      <hr className="mt-4" />
      <div className="mt-2 flex justify-between">
        {navCount > 0 && (
          <button
            onClick={() => onPrev()}
            className="bg-[#0ca5eb] text-white h-8 w-fit px-4 flex items-center justify-center rounded text-nowrap"
          >
            PREV
          </button>
        )}
        {navCount < nav.length - 1 && (
          <button
            onClick={() => onNext()}
            className="bg-[#0ca5eb] text-white h-8 w-fit px-4 flex items-center justify-center rounded text-nowrap ml-auto"
          >
            NEXT
          </button>
        )}
        {navCount === nav.length - 1 && general?.validatePublish && (
          <button
            onClick={() => onSave()}
            className={`${
              general?.statusActive === 0 || !general?.statusActive
                ? "bg-gray-500"
                : "bg-green-600"
            } text-white h-8 w-fit px-4 flex items-center justify-center rounded text-nowrap ml-auto`}
          >
            {general?.statusActive === 0 || !general?.statusActive
              ? "SAVE AS DRAFT"
              : "PUBLISH NOW"}
          </button>
        )}
      </div>
    </React.Fragment>
  );
}

function Thumbnail() {
  const auth = useAuth();
  const { setGeneral, general } = useBlog();
  const [images, setImages] = useState(null);
  const [imagesUrlSelected, setImagesUrlSelected] = useState(
    general?.thumbnail ?? ""
  );
  const [imagesDescription, setImagesDescription] = useState(
    general?.thumbnail_description ?? ""
  );

  useEffect(() => {
    setImages(auth?.user?.uploaded_pictures);
  }, [auth?.user?.uploaded_pictures]);

  const addNewImage = async (params) => {
    if (params.type.split("/")[0] !== "image") {
      return alert("Please select image");
    } else {
      try {
        const resultUploadImage = await uploadImage(params);
        const resultPushToGallery = await pushToGallery(
          resultUploadImage.data.Location,
          params.name
        );
        setImages([...images, resultPushToGallery]);
        setImagesUrlSelected(resultPushToGallery.url);
        setGeneral({
          ...general,
          thumbnail: resultPushToGallery.url,
        });
        auth.getuser();
      } catch (error) {
        alert("Failed upload image, please try again!");
      }
    }
  };

  const handleChangeImagesDescription = (e) => {
    setImagesDescription(e.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGeneral({
        ...general,
        thumbnail_description: imagesDescription,
      });
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [imagesDescription]);

  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Upload Your Images</p>
      <button
        className="w-36 h-24 flex items-center justify-center bg-gray-100"
        onClick={() => document.getElementById("fileInput").click()}
      >
        <IconFileImagePlus className="w-12 h-12 text-gray-600" />
      </button>
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        className="hidden"
        onChange={(e) => addNewImage(e.target.files[0])}
      />
      <hr className="mt-4" />
      <p className="my-2 font-bold text-xl">Or From Your Images Gallery</p>
      <div className="flex flex-wrap gap-3 h-[20dvh] md:h-[30dvh] overflow-y-auto">
        {images &&
          images
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((data, index) => (
              <button
                key={index}
                onClick={() => {
                  setImagesUrlSelected(data.url);
                  setGeneral({
                    ...general,
                    thumbnail: data.url,
                  });
                }}
                className={`w-36 h-24 flex items-center justify-center bg-gray-100 cursor-pointer hover:border-4 hover:border-gray-400 ${
                  imagesUrlSelected === data.url
                    ? "border-4 border-blue-600"
                    : ""
                }`}
              >
                <img
                  src={data.url}
                  alt={data.name}
                  className="object-cover w-36 h-20"
                />
              </button>
            ))}
      </div>
      <hr className="mt-4" />
      <p className="my-2 font-bold text-xl">Add Your Image Caption</p>
      <p className="text-sm">
        Add description alternative concise and informative, describe the
        essence of the image.
      </p>
      <p className="text-sm">
        <b>Example</b>, Image of a sleeping cat:{" "}
        <b>"A black cat lies on the sofa with its eyes closed."</b>
      </p>
      <div>
        <input
          type="text"
          placeholder="Type here..."
          className="border w-full py-1 px-2 bg-[#f9fbfd]"
          onChange={(e) => handleChangeImagesDescription(e)}
          value={imagesDescription}
        />
      </div>
    </React.Fragment>
  );
}

function Keyword({ publication }) {
  const { general, setGeneral } = useBlog();
  const [topics, setTopics] = useState(general?.topics ?? []);
  const [topic, setTopic] = useState("");
  const [loading, setLoading] = useState(false);
  const handledTopic = (e) => {
    setTopic(e.target.value);
  };
  const handleKeydownTopic = (e) => {
    if (e.key === "Enter") {
      if (e === "") {
        alert("Please enter topic");
      } else {
        if (topics.includes(e.target.value)) {
          alert("Topic already exist");
        } else {
          const data = [...topics, e.target.value];
          setTopics(data);
          setTopic("");
          setGeneral({
            ...general,
            topics: data,
          });
        }
      }
    }
  };
  const handleAI = async () => {
    setLoading(true);
    try {
      if (publication?.link) {
        const publication_text = await getDetail(publication?.link);
        const publication_json = JSON.parse(publication_text.editorState);
        const block = publication_json.blocks;
        const unstyled = block.filter((b) => b.type === "unstyled");
        const filterText = unstyled.filter((b) => b.text !== "");
        const texts = filterText.map((b) => b.text);
        if (texts.length >= 3) {
          const resultAi = await postKeywordFromAi(texts);
          setTopics(resultAi);
        } else {
          alert("Please add more than 3 paragraphs");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert("Failed generate keywords from AI, please try again!");
    }
  };
  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Keywords</p>
      <p className="text-sm">
        Add keywords that describe the content of the publication. Press Enter
        for add keyword.
      </p>
      <div className="mt-2">
        {topics.map((topic, index) => (
          <span
            key={index}
            onClick={() => {
              setTopics(topics.filter((item) => item !== topic));
              setGeneral({
                ...general,
                topics: topics.filter((item) => item !== topic),
              });
            }}
            className="bg-gray-200 px-2 py-1 rounded m-1 inline-flex items-center cursor-pointer"
          >
            {topic}
            <IconClose className="inline-block text-red-400" />
          </span>
        ))}
        <span className="flex">
          <input
            type="text"
            placeholder="Add keyword..."
            className="border p-1"
            onChange={handledTopic}
            onKeyDown={handleKeydownTopic}
            value={topic}
          />
          <button
            className="border"
            title="Generate keywords from AI"
            onClick={handleAI}
          >
            <IconAi className={`w-8 h-8 ${loading && "animate-spin"}`} />
          </button>
        </span>
      </div>
    </React.Fragment>
  );
}

function FAQ() {
  const { general, setGeneral } = useBlog();

  const defaultFAQ = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };

  const [faq, setFaq] = useState(general?.faq ?? defaultFAQ);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleDelete = (index) => {
    const newFaq = {
      ...faq,
      mainEntity: faq.mainEntity.filter((item, i) => i !== index),
    };
    setFaq(newFaq);
    setGeneral({
      ...general,
      faq: newFaq,
    });
  };

  const handleSubmit = () => {
    const newFaq = {
      ...faq,
      mainEntity: [
        ...faq.mainEntity,
        {
          "@type": "Question",
          name: question,
          acceptedAnswer: {
            "@type": "Answer",
            text: answer,
          },
        },
      ],
    };
    setFaq(newFaq);
    setQuestion("");
    setAnswer("");

    setGeneral({
      ...general,
      faq: newFaq,
    });
  };

  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">FAQ (Optional)</p>
      <p className="text-sm">Add Frequently Asked Questions</p>
      <p>Question?</p>
      <input
        type="text"
        placeholder="Type here..."
        className="w-full bg-[#f9fbfd] border p-1 rounded"
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
        value={question}
      />
      <p>Answer</p>
      <textarea
        className={`w-full bg-[#f9fbfd] border p-1 rounded`}
        rows={4}
        placeholder="Write Answer here"
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
        value={answer}
      ></textarea>
      <button
        onClick={handleSubmit}
        className="bg-[#0ca5eb] text-white h-6 w-fit px-2 flex items-center justify-center rounded text-nowrap"
      >
        SUBMIT
      </button>
      {faq && (
        <div className="border mt-4 p-2 rounded bg-[#f9fbfd] overflow-y-auto h-44">
          {faq.mainEntity.map((item, index) => (
            <div key={index}>
              <p>
                <b>Question</b>: {item.name}
              </p>
              <p>
                <b>Answer</b>: {item.acceptedAnswer.text}
              </p>
              <button
                onClick={() => handleDelete(index)}
                className="bg-red-500 text-white text-sm w-fit px-1 flex items-center justify-center rounded text-nowrap"
              >
                Delete
              </button>
              <hr className="mt-2" />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

function ShortDescription({ publication }) {
  const { general, setGeneral } = useBlog();
  const [description, setDescription] = useState(general?.description ?? "");
  const [loading, setLoading] = useState(false);
  const [listDescriptions, setListDescriptions] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGeneral({ ...general, description });
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [description]);

  const handleAI = async () => {
    setLoading(true);
    try {
      if (publication?.link) {
        const publication_text = await getDetail(publication?.link);
        const publication_json = JSON.parse(publication_text.editorState);
        const block = publication_json.blocks;
        const unstyled = block.filter((b) => b.type === "unstyled");
        const filterText = unstyled.filter((b) => b.text !== "");
        const texts = filterText.map((b) => b.text);
        if (texts.length >= 3) {
          const resultAi = await postDescriptionFromAi(texts);
          setDescription(resultAi[0]);
          setListDescriptions(resultAi);
        } else {
          alert("Please add more than 3 paragraphs");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Failed generate keywords from AI, please try again!");
    }
  };

  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Short Description</p>
      <p className="text-sm">
        Add description alternative concise and informative, describe the
        essence of the publication.
      </p>
      <textarea
        className={`w-full bg-[#f9fbfd] border p-1 rounded`}
        rows={4}
        placeholder="Write description here"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <button
        className="border"
        title="Generate keywords from AI"
        onClick={handleAI}
      >
        <IconAi className={`w-8 h-8 ${loading && "animate-spin"}`} />
      </button>
      {listDescriptions.length > 0 && (
        <details>
          <summary>
            <span className="text-sm">More Short Descriptions</span>
          </summary>
          {listDescriptions
            .slice(1, listDescriptions.length)
            .map((item, index) => (
              <button onClick={() => setDescription(item)} key={index} className="w-full p-2 border rounded mt-2 bg-[#f9fbfd]">
                {item}
              </button>
            ))}
        </details>
      )}
    </React.Fragment>
  );
}

function Status() {
  const { general, setGeneral } = useBlog();
  const [active, setActive] = useState(general?.statusActive ?? 0);
  const [status] = useState([
    {
      title: "Private",
      subTitle: "Private document",
      description: "Only you can see this document.",
    },
    {
      title: "Publication",
      subTitle: "Public Data",
      description:
        "Anyone is allowed to import the data without requiring your prior permission.",
    },
    {
      title: "Free",
      subTitle: "Free with permission",
      description:
        "Anyone is allowed to import the data with your prior permission.",
    },
    {
      title: "Paid",
      subTitle: "Data exchange",
      description:
        "Make the data in this publication as paid data, set the price of your data after clicking the button.",
    },
  ]);
  const [showPaid, setShowPaid] = useState(false);
  const [pricePaid, setPricePaid] = useState(general?.price ?? 0);

  useEffect(() => {
    if (active === 3) {
      setShowPaid(true);
    } else {
      setShowPaid(false);
    }
  }, [active]);

  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Status</p>
      <div className="flex flex-wrap justify-center gap-2">
        {status.map((status, index) => (
          <div
            onClick={() => {
              setActive(index);
              setGeneral({
                ...general,
                statusActive: index,
                status: status,
              });
            }}
            key={index}
            className={`border p-3 rounded w-44 cursor-pointer ${
              active === index ? "bg-blue-200" : ""
            }`}
          >
            <p className="font-bold text-xl">{status.title}</p>
            <p className="text-sm">{status.subTitle}</p>
            <hr className="my-2" />
            <p className="text-sm">{status.description}</p>
          </div>
        ))}
      </div>
      <div className={`${showPaid ? "block" : "hidden"}`}>
        <hr className="mt-4" />
        <p className="font-bold text-xl">PAID</p>
        <p className="text-sm">Set up data exchange</p>
        <div>
          <label htmlFor="price">Price (Rp)</label>
          <input
            id="price"
            type="number"
            className="border p-1 ml-2 border-blue-500 rounded"
            onChange={(e) => {
              setPricePaid(parseInt(e.target.value));
              setGeneral({
                ...general,
                price: parseInt(e.target.value),
              });
            }}
            value={pricePaid}
          />
        </div>
        <p className="text-sm mt-4">
          For every data purchase, you will get what you specified (Rp 10.000).
          MAPID will add 30% of the price you specify for service fees, and 10%
          for Ppn (Rp 14.300).
        </p>
        <p className="text-sm mt-4">
          Term of service data exchange:
          <br />
          1. Data source must be legal <br />
          2. You have the right to access the data
        </p>
      </div>
    </React.Fragment>
  );
}

function Summary({ publication }) {
  const { general, setGeneral } = useBlog();
  const [validateTitle, setvalidateTitle] = useState(false);
  const [validateThumbnail, setValidateThumbnail] = useState(false);
  const [validateThumbnailCaption, setValidateThumbnailCaption] =
    useState(false);
  const [validateShortDesctiption, setValidateShortDesctiption] =
    useState(false);
  const [validateKeyword, setValidateKeyword] = useState(false);
  const [validateCategory, setValidateCategory] = useState(false);

  useEffect(() => {
    if (general?.statusActive !== 0) {
      if (
        publication?.title !== "" &&
        publication?.title !== "Untitled Document"
      ) {
        setvalidateTitle(true);
      }
      if (general?.thumbnail && general?.thumbnail !== "") {
        setValidateThumbnail(true);
      }
      if (
        general?.thumbnail_description &&
        general?.thumbnail_description !== ""
      ) {
        setValidateThumbnailCaption(true);
      }
      if (general?.description && general?.description !== "") {
        setValidateShortDesctiption(true);
      }
      if (general?.topics && general?.topics?.length !== 0) {
        setValidateKeyword(true);
      }
      if (general?.category && general?.category !== "") {
        setValidateCategory(true);
      }
    }
    if (general?.statusActive === 0 || !general?.statusActive) {
      setvalidateTitle(true);
      setValidateThumbnail(true);
      setValidateThumbnailCaption(true);
      setValidateShortDesctiption(true);
      setValidateKeyword(true);
      setValidateCategory(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      validateTitle &&
      validateThumbnail &&
      validateThumbnailCaption &&
      validateShortDesctiption &&
      validateKeyword &&
      validateCategory
    ) {
      setGeneral({
        ...general,
        validatePublish: true,
      });
    } else {
      setGeneral({
        ...general,
        validatePublish: false,
      });
    }

    // eslint-disable-next-line
  }, [
    validateTitle,
    validateThumbnail,
    validateThumbnailCaption,
    validateShortDesctiption,
    validateKeyword,
    validateCategory,
  ]);

  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Summary</p>

      <table>
        <tbody>
          <tr className={`${validateTitle ? "text-gray-800" : "text-red-800"}`}>
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Title
            </td>
            <td
              className={`font-bold ${
                validateTitle ? "" : "border border-red-500"
              }`}
            >
              {validateTitle ? publication?.title ?? "Add Title" : "Add Title"}
            </td>
          </tr>
          <tr
            className={`${
              validateThumbnail ? "text-gray-800" : "text-red-800"
            }`}
          >
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Thumbnail
            </td>
            <td
              className={`${validateThumbnail ? "" : "border border-red-500"}`}
            >
              {validateThumbnail ? (
                general?.thumbnail ? (
                  <img
                    className="object-cover w-32 h-20"
                    src={general?.thumbnail ?? ""}
                    alt={general?.thumbnail_description ?? ""}
                  />
                ) : (
                  "-"
                )
              ) : (
                "Add Thumbnail"
              )}
            </td>
          </tr>
          <tr
            className={`${
              validateThumbnailCaption ? "text-gray-800" : "text-red-800"
            }`}
          >
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Thumbnail Caption
            </td>
            <td
              className={`${
                validateThumbnailCaption ? "" : "border border-red-500"
              }`}
            >
              <p className="italic">
                {validateThumbnailCaption
                  ? general?.thumbnail_description !== ""
                    ? general?.thumbnail_description
                    : "-"
                  : "Add thumbnail caption"}
              </p>
            </td>
          </tr>
          <tr
            className={`${
              validateShortDesctiption ? "text-gray-800" : "text-red-800"
            }`}
          >
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Short Description
            </td>
            <td
              className={`${
                validateShortDesctiption ? "" : "border border-red-500"
              }`}
            >
              <p className="">
                {validateShortDesctiption
                  ? general?.description ?? "-"
                  : "Add short description"}
              </p>
            </td>
          </tr>
          <tr
            className={`${validateKeyword ? "text-gray-800" : "text-red-800"}`}
          >
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Keyword
            </td>
            <td className={`${validateKeyword ? "" : "border border-red-500"}`}>
              <p className="">
                {validateKeyword
                  ? general?.topics?.join(", ") ?? "-"
                  : "Add keyword"}
              </p>
            </td>
          </tr>
          <tr
            className={`${validateCategory ? "text-gray-800" : "text-red-800"}`}
          >
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Category
            </td>
            <td
              className={`${validateCategory ? "" : "border border-red-500"}`}
            >
              <p className="">
                {validateCategory
                  ? publication?.categorys.find(
                      (item) => item._id === general?.category
                    )?.eng ?? "-"
                  : "Please select category"}
              </p>
            </td>
          </tr>
          <tr className={`text-gray-800`}>
            <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
              Status
            </td>
            <td>
              <p
                className={`font-bold ${
                  general?.statusActive === 0 || !general?.statusActive
                    ? "text-black"
                    : "text-green-800"
                }`}
              >
                {general?.status?.title ?? "Private"}(
                {general?.status?.subTitle ?? "Private document"})
              </p>
            </td>
          </tr>
          {general?.statusActive === 3 && (
            <tr className={`text-gray-800`}>
              <td className="pr-4 md:pr-8 py-1 font-bold whitespace-nowrap">
                Price
              </td>
              <td>
                <p className={`font-bold text-green-800`}>
                  Rp {new Intl.NumberFormat().format(general?.price ?? 0)}
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

function Category(params) {
  const { general, setGeneral } = useBlog();
  const [category, setCategory] = useState(general?.category ?? "");
  return (
    <React.Fragment>
      <p className="my-2 font-bold text-xl">Category</p>
      <hr className="my-2" />
      <select
        className="w-full bg-[#f9fbfd] border p-2 rounded"
        onChange={(e) => {
          setCategory(e.target.value);
          setGeneral({
            ...general,
            category: e.target.value,
          });
        }}
        value={category}
      >
        <option value={""} disabled>
          Select Category
        </option>
        {params?.categorys.map((item, index) => (
          <option key={index} value={item._id}>
            {item.eng}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
}

function EditLocation({ publication }) {
  const { general } = useBlog();

  const [viewport, setViewport] = useState({
    latitude:
      general?.location?.coordinates[1] === 0
        ? -4.7
        : general?.location?.coordinates[1],
    longitude:
      general?.location?.coordinates[0] === 0
        ? 110
        : general?.location?.coordinates[0],
    zoom: 5,
  });
  const [geografis, setGeografis] = useState({
    prov: general?.address?.PROVINSI ?? "DKI JAKARTA",
    city: general?.address?.KAB_KOT ?? "KOTA JAKARTA PUSAT",
    district: general?.address?.KECAMATAN ?? "TANAH ABANG",
    subdistrict: general?.address?.DESA ?? "KEBON MELATI",
  });
  const [loading, setLoading] = useState({
    status: false,
    message: "Something went wrong, please try again",
  });

  const getCity = async (e) => {
    try {
      const result = await generateCity(e.longitude, e.latitude);
      setGeografis({
        ...geografis,
        prov: result.provinsi,
        city: result.kota,
        district: result.kecamatan,
        subdistrict: result.kelurahan,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (viewport.latitude !== -4.7 && viewport.longitude !== 110) {
        getCity(viewport);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [viewport]);

  const save = async () => {
    try {
      setLoading({
        status: true,
        message: "Saving...",
      });
      const body = {
        blog_id: publication?._id ?? "",
        DESA: geografis.subdistrict,
        KECAMATAN: geografis.district,
        KAB_KOT: geografis.city,
        PROVINSI: geografis.prov,
        lat: viewport.latitude,
        long: viewport.longitude,
      };
      await editLocation(body);

      setLoading({
        status: false,
        message: "Success",
      });

      setTimeout(() => {
        setLoading({
          status: false,
          message: "",
        });
      }, 3000);
    } catch (error) {
      setLoading({
        status: false,
        message: "Something went wrong, please try again",
      });
    }
  };
  return (
    <div>
      <p className="my-2 font-bold text-xl">Location</p>
      <hr className="my-2" />
      <div className="relative">
        <MapGL
          style={{ width: "100%", height: "400px" }}
          mapStyle="mapbox://styles/egipratama/ckwp3rwkf8ooz14oc9zzsgp8s"
          accessToken={
            "pk.eyJ1IjoiZWdpcHJhdGFtYSIsImEiOiJjbHN5cHU3dXYwZzE4MmlvZTYwaHR5Mmw4In0.-87eZoRzx_uvOrFlOqGtvQ"
          }
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          onViewportChange={setViewport}
        >
          <NavigationControl showCompass showZoom position="top-right" />
        </MapGL>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <IconLocationPin className="w-6 h-6 text-red-500 animate-bounce" />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <div className="">
          <label>Longitude</label>
          <input
            type="number"
            value={viewport.longitude}
            onChange={(e) =>
              setViewport({ ...viewport, longitude: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
        <div className="">
          <label>Latitude</label>
          <input
            type="number"
            value={viewport.latitude}
            onChange={(e) =>
              setViewport({ ...viewport, latitude: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
        <div className="">
          <label>Provinsi</label>
          <input
            type="text"
            value={geografis.prov}
            onChange={(e) =>
              setGeografis({ ...geografis, prov: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
        <div className="">
          <label>Kabupaten/Kota</label>
          <input
            type="text"
            value={geografis.city}
            onChange={(e) =>
              setGeografis({ ...geografis, city: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
        <div className="">
          <label>Kecamatan</label>
          <input
            type="text"
            value={geografis.district}
            onChange={(e) =>
              setGeografis({ ...geografis, district: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
        <div className="">
          <label>Desa</label>
          <input
            type="text"
            value={geografis.subdistrict}
            onChange={(e) =>
              setGeografis({ ...geografis, subdistrict: e.target.value })
            }
            className="w-full border p-1 rounded"
          />
        </div>
      </div>
      <button
        onClick={() => save()}
        className="bg-[#0ca5eb] text-white h-8 w-fit px-4 flex items-center justify-center rounded text-nowrap mt-2 mx-auto"
      >
        {loading.status ? (
          <IconBxLoaderCircle className="animate-spin" />
        ) : (
          "Save"
        )}
      </button>
      {loading.status && (
        <p className="text-center text-xs text-green-700">{loading.message}</p>
      )}
    </div>
  );
}
